"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Patient API
 * API for patient management
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultApi = exports.DefaultApiFactory = exports.DefaultApiFp = exports.DefaultApiAxiosParamCreator = exports.RecordUnderCareRequestStatus = exports.PatientUnderCareRelationship = exports.Gender = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.Gender = {
    Male: 'male',
    Female: 'female'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.PatientUnderCareRelationship = {
    Child: 'CHILD',
    Spouse: 'SPOUSE',
    Parent: 'PARENT',
    Grandparent: 'GRANDPARENT',
    Grandchild: 'GRANDCHILD',
    Partner: 'PARTNER',
    Pet: 'PET',
    Other: 'OTHER'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.RecordUnderCareRequestStatus = {
    Pending: 'PENDING',
    Approved: 'APPROVED',
    Denied: 'DENIED'
};
/**
 * DefaultApi - axios parameter creator
 * @export
 */
var DefaultApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHealthCheck: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/healthCheck";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsGetIntegrationCheck: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/integrations/integrationsCheck";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateLocationPatientRecordDto} createLocationPatientRecordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPatientRecordCreate: function (locationId, createLocationPatientRecordDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('locationPatientRecordCreate', 'locationId', locationId);
                    // verify required parameter 'createLocationPatientRecordDto' is not null or undefined
                    (0, common_1.assertParamExists)('locationPatientRecordCreate', 'createLocationPatientRecordDto', createLocationPatientRecordDto);
                    localVarPath = "/locations/{location_id}/patients/records"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createLocationPatientRecordDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPatientRecordFind: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('locationPatientRecordFind', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/patients/records"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPatientRecordFindOne: function (locationId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('locationPatientRecordFindOne', 'locationId', locationId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('locationPatientRecordFindOne', 'id', id);
                    localVarPath = "/locations/{location_id}/patients/records/{id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPatientRecordRemove: function (locationId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('locationPatientRecordRemove', 'locationId', locationId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('locationPatientRecordRemove', 'id', id);
                    localVarPath = "/locations/{location_id}/patients/records/{id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} id
         * @param {UpdateLocationPatientRecordDto} updateLocationPatientRecordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPatientRecordUpdate: function (locationId, id, updateLocationPatientRecordDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('locationPatientRecordUpdate', 'locationId', locationId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('locationPatientRecordUpdate', 'id', id);
                    // verify required parameter 'updateLocationPatientRecordDto' is not null or undefined
                    (0, common_1.assertParamExists)('locationPatientRecordUpdate', 'updateLocationPatientRecordDto', updateLocationPatientRecordDto);
                    localVarPath = "/locations/{location_id}/patients/records/{id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateLocationPatientRecordDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} patientRecordId
         * @param {AddPrescriptionDto} addPrescriptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordAddPrescription: function (patientRecordId, addPrescriptionDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'patientRecordId' is not null or undefined
                    (0, common_1.assertParamExists)('patientRecordAddPrescription', 'patientRecordId', patientRecordId);
                    // verify required parameter 'addPrescriptionDto' is not null or undefined
                    (0, common_1.assertParamExists)('patientRecordAddPrescription', 'addPrescriptionDto', addPrescriptionDto);
                    localVarPath = "/patients/records/{patient_record_id}/add_prescription"
                        .replace("{".concat("patient_record_id", "}"), encodeURIComponent(String(patientRecordId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(addPrescriptionDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {CreatePatientRecordDto} createPatientRecordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordCreate: function (createPatientRecordDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'createPatientRecordDto' is not null or undefined
                    (0, common_1.assertParamExists)('patientRecordCreate', 'createPatientRecordDto', createPatientRecordDto);
                    localVarPath = "/patients/records";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createPatientRecordDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordFindAll: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/patients/records";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordFindOne: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('patientRecordFindOne', 'id', id);
                    localVarPath = "/patients/records/{id}"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} patientRecordId
         * @param {InitLocationPatientRecordDto} initLocationPatientRecordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordInitLocationPatientRecord: function (patientRecordId, initLocationPatientRecordDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'patientRecordId' is not null or undefined
                    (0, common_1.assertParamExists)('patientRecordInitLocationPatientRecord', 'patientRecordId', patientRecordId);
                    // verify required parameter 'initLocationPatientRecordDto' is not null or undefined
                    (0, common_1.assertParamExists)('patientRecordInitLocationPatientRecord', 'initLocationPatientRecordDto', initLocationPatientRecordDto);
                    localVarPath = "/patients/records/{patient_record_id}/init_location_patient_record"
                        .replace("{".concat("patient_record_id", "}"), encodeURIComponent(String(patientRecordId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(initLocationPatientRecordDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordRemove: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('patientRecordRemove', 'id', id);
                    localVarPath = "/patients/records/{id}"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {UpdatePatientRecordDto} updatePatientRecordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordUpdate: function (id, updatePatientRecordDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('patientRecordUpdate', 'id', id);
                    // verify required parameter 'updatePatientRecordDto' is not null or undefined
                    (0, common_1.assertParamExists)('patientRecordUpdate', 'updatePatientRecordDto', updatePatientRecordDto);
                    localVarPath = "/patients/records/{id}"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updatePatientRecordDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} patientUserId
         * @param {CreateRecordUnderCareDto} createRecordUnderCareDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUnderCareCreate: function (patientUserId, createRecordUnderCareDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'patientUserId' is not null or undefined
                    (0, common_1.assertParamExists)('recordUnderCareCreate', 'patientUserId', patientUserId);
                    // verify required parameter 'createRecordUnderCareDto' is not null or undefined
                    (0, common_1.assertParamExists)('recordUnderCareCreate', 'createRecordUnderCareDto', createRecordUnderCareDto);
                    localVarPath = "/users/patients/{patient_user_id}/records_under_care"
                        .replace("{".concat("patient_user_id", "}"), encodeURIComponent(String(patientUserId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createRecordUnderCareDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} patientUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUnderCareFindAllCaregivers: function (patientUserId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'patientUserId' is not null or undefined
                    (0, common_1.assertParamExists)('recordUnderCareFindAllCaregivers', 'patientUserId', patientUserId);
                    localVarPath = "/users/patients/{patient_user_id}/caregivers"
                        .replace("{".concat("patient_user_id", "}"), encodeURIComponent(String(patientUserId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} patientUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUnderCareFindByPatientUser: function (patientUserId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'patientUserId' is not null or undefined
                    (0, common_1.assertParamExists)('recordUnderCareFindByPatientUser', 'patientUserId', patientUserId);
                    localVarPath = "/users/patients/{patient_user_id}/records_under_care"
                        .replace("{".concat("patient_user_id", "}"), encodeURIComponent(String(patientUserId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} userId
         * @param {CreateRecordUnderCareRequestDto} createRecordUnderCareRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUnderCareRequestCreate: function (userId, createRecordUnderCareRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'userId' is not null or undefined
                    (0, common_1.assertParamExists)('recordUnderCareRequestCreate', 'userId', userId);
                    // verify required parameter 'createRecordUnderCareRequestDto' is not null or undefined
                    (0, common_1.assertParamExists)('recordUnderCareRequestCreate', 'createRecordUnderCareRequestDto', createRecordUnderCareRequestDto);
                    localVarPath = "/users/patients/{user_id}/records_under_care/requests"
                        .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createRecordUnderCareRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} userId
         * @param {RecordUnderCareRequestStatus} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUnderCareRequestFind: function (userId, status, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'userId' is not null or undefined
                    (0, common_1.assertParamExists)('recordUnderCareRequestFind', 'userId', userId);
                    localVarPath = "/users/patients/{user_id}/records_under_care/requests"
                        .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (status !== undefined) {
                        localVarQueryParameter['status'] = status;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} userId
         * @param {string} requestId
         * @param {UpdateRecordUnderCareRequestDto} updateRecordUnderCareRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUnderCareRequestUpdate: function (userId, requestId, updateRecordUnderCareRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'userId' is not null or undefined
                    (0, common_1.assertParamExists)('recordUnderCareRequestUpdate', 'userId', userId);
                    // verify required parameter 'requestId' is not null or undefined
                    (0, common_1.assertParamExists)('recordUnderCareRequestUpdate', 'requestId', requestId);
                    // verify required parameter 'updateRecordUnderCareRequestDto' is not null or undefined
                    (0, common_1.assertParamExists)('recordUnderCareRequestUpdate', 'updateRecordUnderCareRequestDto', updateRecordUnderCareRequestDto);
                    localVarPath = "/users/patients/{user_id}/records_under_care/requests/{request_id}"
                        .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)))
                        .replace("{".concat("request_id", "}"), encodeURIComponent(String(requestId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateRecordUnderCareRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.DefaultApiAxiosParamCreator = DefaultApiAxiosParamCreator;
/**
 * DefaultApi - functional programming interface
 * @export
 */
var DefaultApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.DefaultApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHealthCheck: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appGetHealthCheck(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsGetIntegrationCheck: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.integrationsGetIntegrationCheck(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateLocationPatientRecordDto} createLocationPatientRecordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPatientRecordCreate: function (locationId, createLocationPatientRecordDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.locationPatientRecordCreate(locationId, createLocationPatientRecordDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPatientRecordFind: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.locationPatientRecordFind(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPatientRecordFindOne: function (locationId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.locationPatientRecordFindOne(locationId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPatientRecordRemove: function (locationId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.locationPatientRecordRemove(locationId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} id
         * @param {UpdateLocationPatientRecordDto} updateLocationPatientRecordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPatientRecordUpdate: function (locationId, id, updateLocationPatientRecordDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.locationPatientRecordUpdate(locationId, id, updateLocationPatientRecordDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} patientRecordId
         * @param {AddPrescriptionDto} addPrescriptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordAddPrescription: function (patientRecordId, addPrescriptionDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patientRecordAddPrescription(patientRecordId, addPrescriptionDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CreatePatientRecordDto} createPatientRecordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordCreate: function (createPatientRecordDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patientRecordCreate(createPatientRecordDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordFindAll: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patientRecordFindAll(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordFindOne: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patientRecordFindOne(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} patientRecordId
         * @param {InitLocationPatientRecordDto} initLocationPatientRecordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordInitLocationPatientRecord: function (patientRecordId, initLocationPatientRecordDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patientRecordInitLocationPatientRecord(patientRecordId, initLocationPatientRecordDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordRemove: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patientRecordRemove(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {UpdatePatientRecordDto} updatePatientRecordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordUpdate: function (id, updatePatientRecordDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patientRecordUpdate(id, updatePatientRecordDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} patientUserId
         * @param {CreateRecordUnderCareDto} createRecordUnderCareDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUnderCareCreate: function (patientUserId, createRecordUnderCareDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.recordUnderCareCreate(patientUserId, createRecordUnderCareDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} patientUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUnderCareFindAllCaregivers: function (patientUserId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.recordUnderCareFindAllCaregivers(patientUserId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} patientUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUnderCareFindByPatientUser: function (patientUserId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.recordUnderCareFindByPatientUser(patientUserId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} userId
         * @param {CreateRecordUnderCareRequestDto} createRecordUnderCareRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUnderCareRequestCreate: function (userId, createRecordUnderCareRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.recordUnderCareRequestCreate(userId, createRecordUnderCareRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} userId
         * @param {RecordUnderCareRequestStatus} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUnderCareRequestFind: function (userId, status, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.recordUnderCareRequestFind(userId, status, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} userId
         * @param {string} requestId
         * @param {UpdateRecordUnderCareRequestDto} updateRecordUnderCareRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUnderCareRequestUpdate: function (userId, requestId, updateRecordUnderCareRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.recordUnderCareRequestUpdate(userId, requestId, updateRecordUnderCareRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.DefaultApiFp = DefaultApiFp;
/**
 * DefaultApi - factory interface
 * @export
 */
var DefaultApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.DefaultApiFp)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHealthCheck: function (options) {
            return localVarFp.appGetHealthCheck(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsGetIntegrationCheck: function (options) {
            return localVarFp.integrationsGetIntegrationCheck(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateLocationPatientRecordDto} createLocationPatientRecordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPatientRecordCreate: function (locationId, createLocationPatientRecordDto, options) {
            return localVarFp.locationPatientRecordCreate(locationId, createLocationPatientRecordDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPatientRecordFind: function (locationId, options) {
            return localVarFp.locationPatientRecordFind(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPatientRecordFindOne: function (locationId, id, options) {
            return localVarFp.locationPatientRecordFindOne(locationId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPatientRecordRemove: function (locationId, id, options) {
            return localVarFp.locationPatientRecordRemove(locationId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} id
         * @param {UpdateLocationPatientRecordDto} updateLocationPatientRecordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationPatientRecordUpdate: function (locationId, id, updateLocationPatientRecordDto, options) {
            return localVarFp.locationPatientRecordUpdate(locationId, id, updateLocationPatientRecordDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} patientRecordId
         * @param {AddPrescriptionDto} addPrescriptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordAddPrescription: function (patientRecordId, addPrescriptionDto, options) {
            return localVarFp.patientRecordAddPrescription(patientRecordId, addPrescriptionDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CreatePatientRecordDto} createPatientRecordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordCreate: function (createPatientRecordDto, options) {
            return localVarFp.patientRecordCreate(createPatientRecordDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordFindAll: function (options) {
            return localVarFp.patientRecordFindAll(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordFindOne: function (id, options) {
            return localVarFp.patientRecordFindOne(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} patientRecordId
         * @param {InitLocationPatientRecordDto} initLocationPatientRecordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordInitLocationPatientRecord: function (patientRecordId, initLocationPatientRecordDto, options) {
            return localVarFp.patientRecordInitLocationPatientRecord(patientRecordId, initLocationPatientRecordDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordRemove: function (id, options) {
            return localVarFp.patientRecordRemove(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {UpdatePatientRecordDto} updatePatientRecordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientRecordUpdate: function (id, updatePatientRecordDto, options) {
            return localVarFp.patientRecordUpdate(id, updatePatientRecordDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} patientUserId
         * @param {CreateRecordUnderCareDto} createRecordUnderCareDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUnderCareCreate: function (patientUserId, createRecordUnderCareDto, options) {
            return localVarFp.recordUnderCareCreate(patientUserId, createRecordUnderCareDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} patientUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUnderCareFindAllCaregivers: function (patientUserId, options) {
            return localVarFp.recordUnderCareFindAllCaregivers(patientUserId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} patientUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUnderCareFindByPatientUser: function (patientUserId, options) {
            return localVarFp.recordUnderCareFindByPatientUser(patientUserId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} userId
         * @param {CreateRecordUnderCareRequestDto} createRecordUnderCareRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUnderCareRequestCreate: function (userId, createRecordUnderCareRequestDto, options) {
            return localVarFp.recordUnderCareRequestCreate(userId, createRecordUnderCareRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} userId
         * @param {RecordUnderCareRequestStatus} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUnderCareRequestFind: function (userId, status, options) {
            return localVarFp.recordUnderCareRequestFind(userId, status, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} userId
         * @param {string} requestId
         * @param {UpdateRecordUnderCareRequestDto} updateRecordUnderCareRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUnderCareRequestUpdate: function (userId, requestId, updateRecordUnderCareRequestDto, options) {
            return localVarFp.recordUnderCareRequestUpdate(userId, requestId, updateRecordUnderCareRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.DefaultApiFactory = DefaultApiFactory;
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
var DefaultApi = /** @class */ (function (_super) {
    __extends(DefaultApi, _super);
    function DefaultApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.appGetHealthCheck = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).appGetHealthCheck(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.integrationsGetIntegrationCheck = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).integrationsGetIntegrationCheck(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {CreateLocationPatientRecordDto} createLocationPatientRecordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.locationPatientRecordCreate = function (locationId, createLocationPatientRecordDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).locationPatientRecordCreate(locationId, createLocationPatientRecordDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.locationPatientRecordFind = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).locationPatientRecordFind(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.locationPatientRecordFindOne = function (locationId, id, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).locationPatientRecordFindOne(locationId, id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.locationPatientRecordRemove = function (locationId, id, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).locationPatientRecordRemove(locationId, id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} id
     * @param {UpdateLocationPatientRecordDto} updateLocationPatientRecordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.locationPatientRecordUpdate = function (locationId, id, updateLocationPatientRecordDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).locationPatientRecordUpdate(locationId, id, updateLocationPatientRecordDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} patientRecordId
     * @param {AddPrescriptionDto} addPrescriptionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.patientRecordAddPrescription = function (patientRecordId, addPrescriptionDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).patientRecordAddPrescription(patientRecordId, addPrescriptionDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CreatePatientRecordDto} createPatientRecordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.patientRecordCreate = function (createPatientRecordDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).patientRecordCreate(createPatientRecordDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.patientRecordFindAll = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).patientRecordFindAll(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.patientRecordFindOne = function (id, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).patientRecordFindOne(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} patientRecordId
     * @param {InitLocationPatientRecordDto} initLocationPatientRecordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.patientRecordInitLocationPatientRecord = function (patientRecordId, initLocationPatientRecordDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).patientRecordInitLocationPatientRecord(patientRecordId, initLocationPatientRecordDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.patientRecordRemove = function (id, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).patientRecordRemove(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {UpdatePatientRecordDto} updatePatientRecordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.patientRecordUpdate = function (id, updatePatientRecordDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).patientRecordUpdate(id, updatePatientRecordDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} patientUserId
     * @param {CreateRecordUnderCareDto} createRecordUnderCareDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.recordUnderCareCreate = function (patientUserId, createRecordUnderCareDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).recordUnderCareCreate(patientUserId, createRecordUnderCareDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} patientUserId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.recordUnderCareFindAllCaregivers = function (patientUserId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).recordUnderCareFindAllCaregivers(patientUserId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} patientUserId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.recordUnderCareFindByPatientUser = function (patientUserId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).recordUnderCareFindByPatientUser(patientUserId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} userId
     * @param {CreateRecordUnderCareRequestDto} createRecordUnderCareRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.recordUnderCareRequestCreate = function (userId, createRecordUnderCareRequestDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).recordUnderCareRequestCreate(userId, createRecordUnderCareRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} userId
     * @param {RecordUnderCareRequestStatus} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.recordUnderCareRequestFind = function (userId, status, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).recordUnderCareRequestFind(userId, status, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} userId
     * @param {string} requestId
     * @param {UpdateRecordUnderCareRequestDto} updateRecordUnderCareRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.recordUnderCareRequestUpdate = function (userId, requestId, updateRecordUnderCareRequestDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).recordUnderCareRequestUpdate(userId, requestId, updateRecordUnderCareRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DefaultApi;
}(base_1.BaseAPI));
exports.DefaultApi = DefaultApi;
